.ReactTable.MuiTable-root {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}
.ReactTable.MuiTable-root .rt-thead.-header {
    box-shadow: none;
    border-bottom: 1px solid rgba(224,224,224,1);
}
.ReactTable.MuiTable-root .rt-thead .rt-th,
.ReactTable.MuiTable-root .rt-thead .rt-td {
    font-weight: 500;
}
.ReactTable.MuiTable-root .rt-th,
.ReactTable.MuiTable-root .rt-td {
    border-right: 0;
    align-items: center;
}
.ReactTable.MuiTable-root .rt-th,
.ReactTable.MuiTable-root .rt-td {
    padding: 16px;
}
.ReactTable.MuiTable-root.dense .rt-th,
.ReactTable.MuiTable-root.dense .rt-td {
    padding: 6px 16px;
}
.ReactTable.MuiTable-root.-nopaddingh .rt-th,
.ReactTable.MuiTable-root.-nopaddingh .rt-td {
    padding-left: 0;
    padding-right: 0;
}
.ReactTable.MuiTable-root .rt-thead.-filters .rt-tr {
    background-color: rgb(234,234,234);
}
.ReactTable.MuiTable-root .rt-thead.-filters .rt-th,
.ReactTable.MuiTable-root .rt-thead.-filters .rt-td {
    padding: 5px 16px;
    color: grey;
}
.ReactTable.MuiTable-root .rt-thead.-filters .rt-th .MuiSvgIcon-fontSizeSmall,
.ReactTable.MuiTable-root .rt-thead.-filters .rt-td .MuiSvgIcon-fontSizeSmall {
    font-size: 1rem;
}
.ReactTable.MuiTable-root .rt-thead.-filters .rt-th input,
.ReactTable.MuiTable-root .rt-thead.-filters .rt-td input {
    border: 0;
    background-color: inherit;
    color: inherit;
    max-width: 100px;
}
.ReactTable.MuiTable-root .rt-thead.-filters .rt-th input:focus,
.ReactTable.MuiTable-root .rt-thead.-filters .rt-td input:focus {
    border: 0;
    outline: none;
}

/*PAGINATION*/
.ReactTable.MuiTable-root .-pagination{
    display: inline-flex;
    box-shadow: none;
}
.ReactTable.MuiTable-root .-pagination .-previous,
.ReactTable.MuiTable-root .-pagination .-next{
    -webkit-box-flex:none;
    -ms-flex:none;
    flex:none;
    width: 80px;
    text-align:center
}
@media only screen and (max-width: 600px) {
    .ReactTable.MuiTable-root .-pagination{
        display: block
    }
    .ReactTable.MuiTable-root .-pagination .-previous,
    .ReactTable.MuiTable-root .-pagination .-next{
        width: 100%;
    }
}
.ReactTable.MuiTable-root .-pagination .-pageJump input,
.ReactTable.MuiTable-root .-pagination input,
.ReactTable.MuiTable-root .-pagination select{
    color: rgba(0,0,0,0.6);
}
.ReactTable.MuiTable-root .rt-tbody .rt-td .table_actions .MuiTextField-root .MuiInput-formControl {
    margin-top: 0;
}

/*ROW ACTIONS*/
.ReactTable.MuiTable-root .rt-td.TableActions .MuiInput-formControl{
    margin-top: 0;
}