/*APP*/
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden !important;
}

/*SCROLLBAR*/
*::-webkit-scrollbar {width: 0.8em}
*::-webkit-scrollbar-track {-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.00)}
*::-webkit-scrollbar-thumb {background-color: rgba(0,0,0,.15);outline: 0;border-radius: 3px;}